import styled from 'styled-components'
import { up } from 'src/utils/media'

const StyledHeading = styled.span`
  display: inline-block;
  font-family: ${props => props.theme.fontFamily.primary};
  color: ${props => props.theme.colors.bodyTextDark};
  font-size: 2.2rem;
  letter-spacing: 0;
  line-height: 1.3;
  font-weight: 500;

  ${up('mobile')} {
    font-size: 2.7rem;
  }

  ${up('desktop')} {
    font-size: 3.4rem;
  }

  ${up('largeDesktop')} {
    font-size: 5.5rem;
  }
`

export default StyledHeading
