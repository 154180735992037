import React from 'react'
import { Grid, Row, Col } from '@baevra/grid'
import Wrapper from './style'

interface Props {
  body: string
}

const NewsBody: React.FC<Props> = props => {
  const { body } = props

  return (
    <Wrapper>
      <Grid xsColumnGap={0} lgColumnGap={20}>
        <Row xsMarginBottom={40} lgMarginBottom={80}>
          <Col xs={12} lg={4} lgOffset={4} dangerouslySetInnerHTML={{ __html: body }} />
        </Row>
      </Grid>
    </Wrapper>
  )
}

export default NewsBody
