import styled from 'styled-components'
import { up } from 'src/utils/media'

const Wrapper = styled.section`
  display: flex;
  flex-flow: column;
  align-items: center;
  margin: 5rem auto;
  width: 100%;
  max-width: ${props => props.theme.containerWidth.sm};

  & .grid-column {
    display: flex;
    flex-flow: column;

    & > div, & > p {
      margin-bottom: 4rem;
      font-size: 1.8rem;

      &:nth-last-child(1) {
        margin-bottom: 0!important;
      }
    }
  }

  & h2 {
    text-transform: uppercase;
  }

  figure {
    display: block;
    width: 100%;
    height: auto;
    margin: 0 auto 4rem 0;

    & > img {
      display: block;
      width: 100%;
      height: auto;
      margin: 0 auto 4rem 0;
    }
  }

  img + figcaption {
    display: block;
    text-align: center;
    font-family: ${props => props.theme.fontFamily.primary};
    color: ${props => props.theme.colors.subtitle};
    font-size: 1.6rem;
    letter-spacing: 0;
    line-height: 1.35;
    font-weight: 500;

    ${up('mobile')} {
      font-size: 1.4rem;
    }

    ${up('desktop')} {
      font-size: 1.7rem;
    }

    ${up('largeDesktop')} {
      font-size: 2.7rem;
    }
  }

  .quote {
    font-family: ${props => props.theme.fontFamily.primary};
    color: ${props => props.theme.colors.bodyTextDark};
    font-size: 1.8rem!important;
    letter-spacing: 0;
    line-height: 1.3;
    font-weight: 500;
    font-style: italic;
    padding-top: 4rem;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: 0;
      width: 2.4rem;
      height: 1.7rem;
      background-image: url(${require('src/static/icons/Quote.svg')});
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
    }

    ${up('mobile')} {
      font-size: 1.8rem!important;
    }

    ${up('tablet')}{    
      padding-top: 0;
      padding-left: 8rem;

      &::before {
        top: .5rem;
        left: 0;
        transform: none;
      }
    }

    ${up('desktop')} {
      font-size: 2.5rem!important;

      &::before {
        width: 2.9rem;
        height: 2.1rem;
      }
    }

    ${up('largeDesktop')} {
      font-size: 5.6rem!important;
    }
  }

  .author {
    margin-top: 4rem;
    padding: 0 1rem;
    font-size: 1.7rem!important;

    & > .photo {
      display: flex;
      flex-shrink: 0;
      width: 5.6rem;
      height: 5.6rem;
      border-radius: 50%;
      margin-bottom: 2.5rem;
      margin-left: auto;
      margin-right: auto;
      overflow: hidden;

      & > img {
        width: inherit;
        height: inherit;
        object-fit: contain;
      }
    }

    & > .about {
      text-align: center;
      margin: 0 auto;
    }
  }

  ${up('tablet')} {
    max-width: ${props => props.theme.containerWidth.lg};
    margin: 8rem auto;
    text-align: left;
    align-items: flex-start;

    .quote {
      padding-left: 5rem;
    }

    .author {
      display: flex;
      align-items: center;
      margin-bottom: 0;
      padding: 0;
      margin-left: -5rem;

      & > .photo {
        margin: 0 2.4rem 0 0;
      }

      & > .about {
        width: 100%;
        text-align: left;
        margin: 0;
      }
    }
  }

  ${up('desktop')} {
    margin: 10rem auto;

    & .grid-column {
      & > div, & > p {
        margin-bottom: 8rem;
        font-size: 2.1rem;
      }
    }

    img + figcaption {      
      padding: 0 10%;
    }

    .quote {
      padding-left: 7rem;

      .author {        
        margin-left: -7rem;
      }
    }
  }

  ${up('largeDesktop')} {
    margin: 20rem auto;

    & .grid-column {
      & > div, & > p {
        margin-bottom: 12rem;
        font-size: 5.6rem;
      }
    }

    figure {
      margin-bottom: 8rem;
    }

    img + figcaption {      
      padding: 0 20%;
    }

    .author {
      font-size: 2.5rem!important;
    }
  }
`

export default Wrapper
