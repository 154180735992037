import React, { useState, useRef } from 'react'
import { Grid, Row, Col } from '@baevra/grid'
import Date from 'src/components/Typography/Date'
import NewsHeading from 'src/components/Typography/News/Heading'
import createShortYear from 'src/utils/createShortYear'
import Wrapper from './style'

interface Props {
  date: {
    day: string
    month: string
    year: string
  }
  caption: string
  poster: string
}

const NewsCaption: React.FC<Props> = props => {
  const { date, caption, poster } = props
  const [isVerticalPoster, setIsVerticalPoster] = useState(false)

  const imageRef = useRef<any>(null)

  const handleLoadPoster = () => {
    const width = imageRef?.current?.naturalWidth ?? 0
    const height = imageRef?.current?.naturalHeight ?? 0

    if ( height > width) {
      setIsVerticalPoster(true)
    }
  }

  return (
    <Wrapper>
      <Grid xsColumnGap={0} lgColumnGap={20}>
        <Row xsMarginBottom={0}>
          <Col xs={12} lg={4} lgOffset={4}>
            <div className="subtitle">
              <Date day={date.day} month={date.month} year={createShortYear(date.year)} />
            </div>
            <div className="title">
              <NewsHeading dangerouslySetInnerHTML={{ __html: caption }} />
            </div>
          </Col>
        </Row>
        <Row xsMarginBottom={0}>
          <Col xs={12} lg={isVerticalPoster ? 4 : 6} lgOffset={isVerticalPoster ? 4 : 3}>
            <div className={isVerticalPoster ? 'poster vertical' : 'poster'}>
              <img ref={imageRef} src={poster} alt={caption} onLoad={handleLoadPoster}/>
            </div>
          </Col>
        </Row>
      </Grid>
    </Wrapper>
  )
}

export default NewsCaption
