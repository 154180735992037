import React from 'react'
import NavLink from 'src/components/NavLink'
import Wrapper from './style'
import createDateComponent from 'src/utils/createDateComponent'
import createShortYear from 'src/utils/createShortYear'

interface Props {
  links: {
    date: string
    slug: string
  }[]
}

const DatesNavigation:React.FC<Props> = (props) => {
  return (
    <Wrapper>
      <ul>
        {
          props.links.map(link => {
            const to = `/news/${link.slug}`
            const date = createDateComponent(link.date)
            const title = `${date.day} ${date.month} ‘${createShortYear(date.year)}`

            return (
              <li key={link.slug}>
                <NavLink to={to}>{title}</NavLink>
              </li>
            )
          })
        }
      </ul>
    </Wrapper>
  )
}

export default DatesNavigation
