import React from 'react'
import StyledHeading from './style'

interface Props {
  className?: string
  children?: React.ReactNode
  dangerouslySetInnerHTML?: any
}

const NewsHeading:React.FC<Props> = props => {
  const { className, children, ...other } = props

  return (
    <StyledHeading
      className={className}
      {...other}
    >
      {children ?? null}
    </StyledHeading>
  )
}

export default NewsHeading
