import styled from 'styled-components'
import { up } from 'src/utils/media'

const Wrapper = styled.div`
  display: block;
  width: ${props => props.theme.containerWidth.sm};
  margin: 0 auto 8rem auto;

  .list {
    display: flex;
    width: 100%;
    flex-flow: row nowrap;
    justify-content: center;
    /* cursor: default; */
  }

  .group {
    display: flex;
    flex-flow: column;
    align-items: center;
    margin-right: 4.5rem;

    &:nth-last-child(1) {
      margin-right: 0;
    }

    .share-button {
      display: block;
      width: 5rem;
      height: 5rem;
      margin-bottom: 1.5rem;
      /* cursor: default!important; */
      transition: all .3s ease-out;

      & svg * {
        /* cursor: default; */
        transition: all .3s ease-out;
      }

      &:hover svg {
        & .b, & .d {
          fill: ${props => props.theme.colors.separator[700]};
        }
      }
    }

    span.counter {
      /* cursor: default; */
      font-family: ${props => props.theme.fontFamily.secondary};
      color: ${props => props.theme.colors.button};
      font-weight: 700;
      line-height: 1.65;
      font-size: 1.2rem;
      letter-spacing: .125em;
    }
  }

  ${up('tablet')} {
    position: fixed;
    width: auto;
    top: 50%;
    left: 10rem;
    transform: translateY(-50%);
    margin: 0;

    .list {
      flex-flow: column;
      justify-content: flex-start;
    }

    .group {
      flex-flow: row nowrap;
      margin-bottom: 2.5rem;

      .share-button {
        margin-bottom: 0;
        margin-right: 2rem;
      }
    }
  }


`

export default Wrapper
