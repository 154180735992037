import React from 'react'
import Layout from 'src/layout'
import ProgressBar from 'src/components/ProgressBar'
import Navbar from 'src/sections/Navbar'
import NewsHeader from 'src/sections/NewsHeader'
import NewsBody from 'src/sections/NewsBody'
import DatesNavigation from 'src/components/DatesNavigation'
import SocialsWidget from 'src/components/SocialsWidget'
import createDateComponent from 'src/utils/createDateComponent'
import removeHTMLTags from 'src/utils/removeHTMLTags'
import SEO from 'src/components/SEO'

const createNews = (news: any) => {
  const title = removeHTMLTags(news.title)
  const caption = removeHTMLTags(news.excerpt)
  const slug = news.slug
  const date = createDateComponent(news.date)
  const image = {
    src: news.better_featured_image?.source_url,
    width: news.better_featured_image?.media_details.width,
    height: news.better_featured_image?.media_details.height
  }
  // check if news.acf.poster.source_url exists
  const poster = news.better_featured_image?.source_url
  const body = news.content.replace(/<style([\s\S]+?)<\/style>/gm, '')

  return {
    title,
    caption,
    slug,
    image,
    poster,
    date,
    body
  }
}

const NewsPage = (props: any) => {
  const news = createNews(props.pageContext.news)
  const links = props.pageContext.relatedNews

  return (
    <Layout>
      <SEO
        title={news.title}
        description={news.caption}
        url={props.location.href}
        ogImage={news.image}
      />
      <ProgressBar />
      <main>
        <Navbar />
        <DatesNavigation links={links}/>
        <NewsHeader
          date={news.date}
          caption={news.title}
          poster={news.poster}    // <= Horizontal Image
          // poster={news.poster}   //  <= Verical Image
        />
        <NewsBody
          body={news.body}
        />
        <SocialsWidget
          url={props.location.href}
          title={news.title}
        />
      </main>
      <div className="bar" style={{ zIndex: -1 }} />
    </Layout>
  )
}


export default NewsPage
