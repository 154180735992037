import React from 'react'
// @ts-ignore
import Vk from 'src/static/icons/vk.inline.svg'
// @ts-ignore
import Fb from 'src/static/icons/fb.inline.svg'
// @ts-ignore
import Tw from 'src/static/icons/tw.inline.svg'
import Wrapper from './style'
import {
  VKShareButton,
  FacebookShareButton,
  TwitterShareButton
} from 'react-share'

interface Props {
  url: string
  title: string
}

const SocialsWidget:React.FC<Props> = (props) => {
  const shareUrl = props.url
  const title = props.title

  return (
    <Wrapper>
      <div className="list">
        <div className="group interactive">
          <VKShareButton
            className="share-button"
            url={shareUrl}
          >
            <Vk />
          </VKShareButton>
        </div>

        <div className="group interactive">
          <FacebookShareButton
            className="share-button"
            url={shareUrl}
            quote={title}
          >
            <Fb />
          </FacebookShareButton>
        </div>

        <div className="group interactive">
          <TwitterShareButton
            className="share-button"
            url={shareUrl}
          >
            <Tw />
          </TwitterShareButton>
        </div>

      </div>
    </Wrapper>
  )
}

export default SocialsWidget
